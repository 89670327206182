export default class PrestacaoService {
  constructor(http) {
    this._http = http
  }

  async getOneByIdConsignacao(id) {
    const { data } = await this._http.get(`/api/prestacoes/consignacao/${id}`)
    return data
  }

  async getPrestacaoPorIdConsignacao(id) {
    const { data } = await this._http.get(
      `/api/prestacoes/prestacao/consignacao/${id}`,
    )
    return data
  }
}
