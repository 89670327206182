<template>
  <Card>
    <template #content>
      <DataTable
        class="p-datatable-sm"
        :paginator="true"
        :rows="5"
        stripedRows
        :loading="loading"
        :value="filteredParcelas"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} prestações"
        responsiveLayout="scroll">
        <template #header>
          <span class="mt-2 mr-2 p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filtros['global'].value"
              placeholder="Pesquisar"
              style="width: 100%" />
          </span>
          <Button
            icon="pi pi-filter-slash"
            label="Limpar"
            class="mt-2 mr-2 p-button-outlined"
            @click="limparFiltro" />
        </template>

        <template #empty> Nenhuma Parcela encontrada. </template>
        <template #loading> Carregando. Por favor aguarde. </template>
        <Column field="mesReferencia" header="Mês/Ano">
          <template #body="{ data }">
            {{ ('0' + data.mesReferencia).slice(-2) }}/{{ data.anoReferencia }}
          </template>
        </Column>
        <Column header="Cadastrado Em">
          <template #body="{ data }">
            {{ data.auditMetadata.cadastradoEm | formatarData }}
          </template>
        </Column>
        <Column field="statusPrestacao" header="Status da Prestação"></Column>
        <Column field="parcela" header="Parcela"></Column>
        <Column header="Valor da Parcela">
          <template #body="{ data }">
            <div class="flex justify-content-end flex-wrap card-container">
              <div class="flex align-items-center justify-content-center">
                {{ data.consignacao.valorParcela | formatarValor }}
              </div>
            </div>
          </template>
        </Column>
        <Column header="Valor da Prestação">
          <template #body="{ data }">
            <div class="flex justify-content-end flex-wrap card-container">
              <div class="flex align-items-center justify-content-center">
                {{ data.valor | formatarValor }}
              </div>
            </div>
          </template>
        </Column>
        <Column header="Valor Descontado">
          <template #body="{ data }">
            <div class="flex justify-content-end flex-wrap card-container">
              <div class="flex align-items-center justify-content-center">
                {{ data.valorDescontado | formatarValor }}
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import PrestacaoService from '@/service/PrestacaoService'

export default {
  data() {
    return {
      id: this.$route.params.idconsig,
      filtros: {
        global: {
          value: '',
        },
      },
      loading: false,
      parcelas: [],
    }
  },

  computed: {
    filteredParcelas() {
      const filterValue = this.filtros['global'].value
        ? this.filtros['global'].value.toLowerCase()
        : ''
      if (!filterValue) return this.parcelas

      return this.parcelas.filter((parcela) =>
        this.customFilter(parcela, filterValue),
      )
    },
  },

  created() {
    this.service = new PrestacaoService(this.$http)
    this.initFiltros()
  },

  mounted() {
    this.carregarDados()
  },

  methods: {
    limparFiltro() {
      this.initFiltros()
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
      }
    },

    carregarDados() {
      this.loading = true
      this.service
        .getOneByIdConsignacao(this.id)
        .then((res) => {
          this.parcelas = this.ordenaParcelas(res)
          this.loading = false
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Ocorreu um erro. Tente novamente!',
            life: 10000,
          })
        })
    },

    ordenaParcelas(res) {
      let parcelasOrdenadas = res
      return parcelasOrdenadas.sort((a, b) => {
        const anoA = a.anoReferencia,
          mesA = a.mesReferencia
        const anoB = b.anoReferencia,
          mesB = b.mesReferencia

        if (anoA > anoB) return -1
        if (anoA < anoB) return +1
        if (mesA > mesB) return -1
        if (mesA < mesB) return +1
        return 0
      })
    },

    customFilter(parcela, filterValue) {
      const dataFormatada = `${('0' + parcela.mesReferencia).slice(-2)}/${
        parcela.anoReferencia
      }`
      const dateMatches = dataFormatada.includes(filterValue)
      const statusMatches = parcela.statusPrestacao
        .toLowerCase()
        .includes(filterValue.toLowerCase())
      return dateMatches || statusMatches
    },
  },
}
</script>

<style></style>
